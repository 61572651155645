import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/aaron/Apps/fresh-pets/src/components/mdx-layout/mdx-layout.js";
import SEO from '../components/seo/seo.js';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title='Terms & Conditions' description='Terms and conditions apply to all bookings at Fresh Pets' keywords={['Fresh', 'Pets', 'Terms']} mdxType="SEO" />
    <h1>{`Terms & Conditions`}</h1>
    <p>{`These terms and conditions apply to all customers and bookings:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`If any pre-booked appointment is not attended or is cancelled with less than 24 hours notice then the client will incur 50% of the appointment fee and a deposit may be required on future bookings.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Fresh Pets' groomers are governed by the Animal Welfare Act 2006. Therefore, groomers have a duty of care to any animal receiving grooming services to prioritise welfare needs over preferred styling choices.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`If the pet's coat is heavily matted and cannot be brushed out without causing pain or distress, then it must be clipped short and advice will be given to the client about how to maintain regrowth. This may result in a higher fee, however an assessment will be made and fees discussed and agreed with clients prior to any grooming in these circumstances.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Grooming may be halted at anytime if Fresh Pets deem an animal unfit to groom and may still require full payment. This will be discussed on an individual basis and is at the discretion of Fresh Pets' staff.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`If during consultation a client decides for any reason that they no longer wish to continue with the grooming service they have booked, they will still have to pay for the appointment, minus the pre paid deposit where applicable.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Fresh Pets may insist clients with elderly, infirm or very nervous dogs stay for the duration of the grooming session and will again prioritise welfare over styling.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The grooming process can expose underlying injuries or health issues or aggravate a current one, which Fresh Pets will not be held liable for. Clients must inform Fresh Pets of all known injuries and illnesses prior to grooming. Fresh Pets may ask the client to sign a disclaimer form before commencing grooming.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Should Fresh Pets decide, at its sole discretion, that urgent veterinary care is required, the client will be responsible for paying all associated costs. If the pet's registered vet is unavailable, Fresh Pets has the authority to seek care from its chosen vet.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`It is possible that an accident could occur whilst grooming. Upmost care and caution will always be taken however, cuts, nicks, scratches and quicking of nails could occur. Any injury knowingly incurred whilst in the care of Fresh Pets will be recorded and the client will be notified.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Fresh Pets will do their upmost to resolve any grievances and kindly ask clients to report any issues to Fresh Pets as soon as possible so appropriate action can be taken.`}</p>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      